<template>
  <a-modal
      title="新建求助单"
      :width="720"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
    >
      <a-form :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
        <a-form-item label="标题">
          <a-input
            v-decorator="['needName', { rules: [{ required: true, message: '请输入标题' }] }]"
          />
        </a-form-item>

        <a-form-item label="处理人">
           <a-select
                :filter-option="false" 
                mode="multiple"
                show-search 
                :allowClear="true"
                placeholder="请选择或搜索处理人"
                v-decorator="['handlerId', { rules: [{ required: true, message: '处理人' }] }]"
                @search="handleSearch"
                >
                    <a-select-option v-for="item in masterList" v-bind:key="item.id" :value="item.id">
                        {{item.name }}
                    </a-select-option>
            </a-select>
        </a-form-item>

        <a-form-item label="起止时间">
          <a-range-picker style="width:100%" format="YYYY-MM-DD" v-decorator="['timeRange']" @change="dateChange">
                      <a-icon slot="suffixIcon" type="calendar" />
           </a-range-picker>
        </a-form-item>

        <a-form-item label="所属项目">
          <a-select style="width:100%" 
                  v-decorator="[
                    'projectId',
                    {rules: [{message: '请选择所属项目'}]}
                  ]">
                  <a-select-option  v-for="item in projectSelect" :key="item.id">{{ item.projectName }}</a-select-option>
                </a-select>
        </a-form-item>

        <a-form-item label="优先级">
          <a-select placeholder="请选择优先级"
                v-decorator="[
                    'level',
                    {rules: [{ message: '请选择优先级'}]}
                  ]">
                <a-select-option value="4">最高</a-select-option>
                <a-select-option value="3">较高</a-select-option>
                <a-select-option value="2">普通</a-select-option>
                <a-select-option value="1">较低</a-select-option>
                <a-select-option value="0">最低</a-select-option>
              </a-select>
        </a-form-item>

        <a-form-item label="描述">
          <j-editor  v-decorator="['content']" triggerChange></j-editor>
        </a-form-item>
      </a-form>
      <span slot="footer" class="dialog-footer">
        <a-button @click="visible = false">取消</a-button>
        <a-button class="ant-btn ant-btn-primary" @click="dataFormSubmit()">确定</a-button>
      </span>
    </a-modal>
</template>
<script>
import JEditor from '@/components/Editor/JEditor'
import {getCompanyUserListAll} from "@/httpsAPI/common";
import {getProjectSelect} from "@/httpsAPI/project";
import { addWorder } from '@/httpsAPI/worder'
    export default {
        data(){
            return {
                form: this.$form.createForm(this),
                visible: false,
                confirmLoading: false,
                timeRange:[],
                startTime:'',
                endTime:'',
                scrollPage: 1,
                masterData: [],
                keyword: '',
                masterList: [],
                projectId: 0,
                projectSelect:[],
            }
        },
        components:{
          JEditor
        },
        methods:{
          // 项目列表
          getProjectSelectList(){
            getProjectSelect({pageNo:1, pageSize: 1000}).then(res=>{
              this.projectSelect = res.data.data
            })
          },
          // 下拉框 分页 搜索
          changeMasterList() {
              getCompanyUserListAll({name: this.keyword})
                      .then(res => {
                          this.masterList = res.data;
                          this.masterData = res.data;
                      })
          },
          //选择渠道下拉框滚动事件
          handlePopupScroll(e) {
              const { target } = e
              const scrollHeight = target.scrollHeight - target.scrollTop
              const clientHeight = target.clientHeight
              // 下拉框不下拉的时候
              if (scrollHeight === 0 && clientHeight === 0) {
                  this.scrollPage = 1
              } else {
                  // 当下拉框滚动条到达底部的时候
                  if (scrollHeight < clientHeight + 5) {
                      this.pageData(1);
                  }else if (scrollHeight == 3208) {
                      this.pageData(2);
                  }
              }
          },
          pageData(type) {
              if (this.masterData.totalPage < this.scrollPage) {
                  this.scrollPage = this.masterData.totalPage;
              } else {
                  type == 1 ? this.scrollPage++ : this.scrollPage--;
                  this.changeMasterList();
                  let newData = [];
                  let max = this.masterData.totalCount;
                  this.masterList.forEach((item, index) => {
                  //当data数组的下标小于max时
                  if (index < max) {
                      newData.push(item)
                  }
                  })
                  this.masterList = newData;
              }
          },
          handleSearch(keyword){
              this.keyword = keyword;
              this.changeMasterList();
          },
          dateChange(date, dateString) {
            this.startTime = dateString[0]
            this.endTime = dateString[1]
          },
          dataFormSubmit(e) {
            this.form.validateFields((err, values) => {
                if (err) {
                    return;
                }
                this.confirmLoading = true;

                values['startTime'] = this.startTime
                values['endTime'] = this.endTime
                
                console.log('Received values of form: ', values);
                addWorder(values).then(res=>{
                  if(res.code==10000) {
                    this.$message.success({
                        content: '操作成功',
                        duration: 1,
                        onClose: () => {
                            this.form.resetFields();
                            this.visible = false;
                            this.$emit('refreshDataList')
                        }
                    })
                  }else {
                    this.$message.error({
                        content: res.msg,
                        duration: 1
                    })
                  }
                })
            });
          },
          handleCancel(e) {
            this.visible = false;
          },
          init () {
            this.visible = true
            this.$nextTick(() => { 
                this.form.resetFields();

                this.changeMasterList()
                this.getProjectSelectList()
                this.form.setFieldsValue({
                      level: '3'
                    })
            });
          }
        }
    }
</script>