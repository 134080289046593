<template>
  <div class="layout-content-page">
    <div>
    <div class="antd-table-search-wrap">
      <a-row :gutter="12">
        <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }" >
          <a-col :md="6" :sm="24">
              <a-form-item label="标题">
                  <a-input v-model="queryParams.needName" placeholder="请输入求助单标题"/>
              </a-form-item>
          </a-col>

          <a-col :md="6" :sm="24">
            <a-form-item label="状态">
              <a-select placeholder="请选择状态" v-model="queryParams.status">
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="0">待处理</a-select-option>
                <a-select-option value="1">处理中</a-select-option>
                <a-select-option value="2">已完成</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

           <a-col :md="6" :sm="24">
            <a-form-item label="级别">
              <a-select placeholder="请选择级别" v-model="queryParams.level">
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="4">最高</a-select-option>
                <a-select-option value="3">较高</a-select-option>
                <a-select-option value="2">普通</a-select-option>
                <a-select-option value="1">较低</a-select-option>
                <a-select-option value="0">最低</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          
          <a-col :span="6">
            <a-form-item>
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button :style="{marginLeft:'20px'}" type="default" @click="resetQueryForm">重置</a-button>
            </a-form-item>
          </a-col>
        </a-form>
      </a-row>
    </div>
    
    <div class="antd-page-content">
      <a-menu mode="horizontal" v-model="currentMenu" :defaultSelectedKeys="[$route.path]" @click="menuClick">
        <a-menu-item v-for="(item,index) in worderStatusList" :key="index" @click="statusClickHandle">{{ item.title }}
        </a-menu-item>
      </a-menu>
      <div class="antd-toolbar">
        <div class="antd-toolbar-left">
          <a-button type="primary" @click="createFormEvt" v-action:addWorder> + 新建求助单</a-button>
        </div>
        <div class="antd-toolbar-right">
          <div class="antd-toolbar-item">
            <a-tooltip>
              <template slot="title">
                <span>刷新</span>
              </template>
              <a-icon type="reload" @click="$refs.table.refresh(true)" />
            </a-tooltip>
          </div>
          <div class="antd-toolbar-item">
            <a-tooltip>
              <template slot="title">
                <span>列设置</span>
              </template>
              <a-popover v-model="settingVisible" placement="bottomLeft" trigger="click">
                <a-icon type="setting" />
                <div slot="content" class="antd-draggable-container">
                  <a-checkbox-group v-model="defaultTableList" @change="tableListHandleChange">
                    <div class="antd-draggable-wrapper" v-for="(item,index) in tableListArr" :key="index">
                      <div class="antd-draggable-list-item" :style="{padding:'4px 0'}">
                        <a-checkbox :value="item.dataIndex">
                          {{ item.title }}
                        </a-checkbox>
                      </div>
                    </div>
                  </a-checkbox-group>
                </div>
              </a-popover>
            </a-tooltip>
          </div>
        </div>
      </div>
      <s-table size="default" ref="table" rowKey="id" :data="loadData" :columns="columns" :selections="true" :pageNum="pageNum"
        :pagination="{pageSizeOptions:['10', '20','50', '100', '150']}">
        <template slot="serial" slot-scope="text, record, index">
            {{ index + 1 }}
          </template>
          <template slot="createTime" slot-scope="text">
            {{text|createTimeFilter}}
          </template>
          <template slot="endTime" slot-scope="text, record">
            {{record.endTime  ? $moment(record.endTime).format('YYYY-MM-DD') : ''}}
          </template>
          <span slot="status" slot-scope="text, record">
              <a-tag color="red" v-if="record.status == 0">待处理</a-tag>
              <a-tag color="orange" v-if="record.status == 1">处理中</a-tag>
              <a-tag color="green" v-if="record.status == 2">已完成</a-tag>
            </span>
          <span slot="level" slot-scope="text, record">
              <a-tag color="red" v-if="record.level == 4">最高</a-tag>
              <a-tag color="orange" v-if="record.level == 3">较高</a-tag>
              <a-tag color="blue" v-if="record.level == 2">普通</a-tag>
              <a-tag color="blue" v-if="record.level == 1">较低</a-tag>
              <a-tag color="blue" v-if="record.level == 0">最低</a-tag>
            </span>
          <template slot="handlerName" slot-scope="text, record">
              <span v-for="(item,index) in record.handlerName" :key="index">{{item}},</span>
            </template>
          <template slot="action" slot-scope="text,record">
            <a href="javascript:void(0)" class="table-operation-action" @click="detailFormEvt(record)" v-action:detailWorder>详情</a>
            <a href="javascript:void(0)" v-if="changeStatus==2" class="table-operation-action" @click="updateFormEvt(record)" v-action:updateWorder>编辑</a>
            <a-popconfirm v-if="changeStatus==2" title="确定删除此求助单么？" ok-text="确定" cancel-text="取消" placement="left" @confirm="deleteEvt(record)">
              <a href="javascript:void(0)" class="table-operation-action" v-action:deleteWorder>删除</a>
            </a-popconfirm>
          </template>
      </s-table>
    </div>

    <!-- 新建弹窗 -->
    <add-form v-if="addFormVisible" ref="addForm" @refreshDataList="$refs.table.refresh(true)" />
    <!-- 修改弹窗 -->
    <update-form v-if="updateFormVisible" ref="updateForm" @refreshDataList="$refs.table.refresh(true)" />
    <!-- 处理弹窗 -->
    <resolve-form v-if="resolveFormVisible" ref="resolveForm" @refreshDataList="$refs.table.refresh(true)" />
    </div>
    <router-view @refreshDataList="resetTable"></router-view>
  </div>
</template>

<script>
import moment from 'moment'
import { getWorderList, deleteWorder } from "@/httpsAPI/worder";
import AddForm from './add'
import UpdateForm from './update'
const worderStatusList = [
  { title: "已收到", value: 1 },
  { title: "已发出", value: 2 }
]
let columns = [
  { title: "标题", dataIndex: "needName", isShow: true },
  { title: "所属项目", dataIndex: "projectName", align: 'center', isShow: true },
  { title: "状态", width:100, dataIndex: "status", align: 'center', isShow: true, scopedSlots:{customRender:"status"} },
  { title: "级别", width:100, dataIndex: "level", align: 'center', isShow: true , scopedSlots:{customRender:"level"}},
  { title: "求助人", dataIndex: "name", align: 'center', isShow: true },
  { title: "计划完成时间", width:200, dataIndex: "endTime", isShow: true, scopedSlots:{customRender:"endTime"} },
  { title: "创建时间", width:200, dataIndex: "createTime", isShow: true, scopedSlots:{customRender:"createTime"} },
  { title: "操作", width:250, dataIndex: "action", align: 'center', scopedSlots: { customRender: 'action' }, isShow: true }
]
export default {
  name: "project",
  data () {
    return {
      queryParams:{},
      pageNum: 1,
      worderStatusList,
      changeStatus: 1,
      status:this.$route.query.changeStatus,
      addFormVisible: false,
      updateFormVisible: false,
      resolveFormVisible: false,
      loadData: parameter => {
        this.pageNum = parameter.pageNo
        this.queryParams.worderType = this.changeStatus

        return getWorderList(Object.assign(parameter,this.queryParams)).then(res => {
          return res.data
        })
      },
      tableListArr: (() => {
        return columns.filter(item => {
          return item.dataIndex
        })
      })(),
      defaultTableList: (() => {
        let arr = [];
        columns.map(item => {
          if (item.isShow) {
            arr.push(item.dataIndex)
          }
        })
        return arr
      })(),
      currentMenu: [0],
      settingVisible: false,
      form: this.$form.createForm(this, { name: 'searchProject' })
    }
  },
  components: {
    AddForm,
    UpdateForm
  },
  computed: {
    columns () {
      return columns.filter(item => {
        return item.isShow
      })
    }
  },
  filters: {
    createTimeFilter (value) {
      return value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '';
    }
  },
  created(){
  },
  methods: {
    // 刷新表格数据
    resetTable(pageNo){ 
      console.log("1111")
      console.log('pageNo', this.$refs.table.localPagination.current)
      this.$refs.table.localPagination.current = Number(pageNo)
      this.$refs.table.refresh(true)
    },
    menuClick({key}) {
      // 获取到当前的key,并且跳转
      this.$router.push({
          path: '/worder/list'
      })
    },
    statusClickHandle (item) {
      this.currentMenu = item.key + 1
      this.changeStatus = item.key + 1
      if(this.changeStatus==2){
          columns.filter(item => {
             if(item.title.indexOf("求助人")>-1){
               item.title = "处理人"
               item.dataIndex = "handlerName"
               item.scopedSlots = {customRender:"handlerName"}
             }
          })
      } else {
          columns.filter(item => {
             if(item.title.indexOf("处理人")>-1){
               item.title = "求助人"
               item.dataIndex = "name"
               item.scopedSlots = {}
             }
          })
      }
      this.$refs.table.refresh(true);
    },
    // 重置搜索form
    resetQueryForm() {
      this.queryParams = {};
      this.$refs.table.refresh(true);
    },
    tableListHandleChange (checkedVal) {
      columns.forEach(item => {
        if (checkedVal.includes(item.dataIndex)) {
          item.isShow = true
        } else {
          item.isShow = false
        }
      })
    },
    // 新建
    createFormEvt () {
      this.addFormVisible = true
      this.$nextTick(() => {
        this.$refs.addForm.init()
      })
    },
    // 修改
    updateFormEvt(record) {
      if(record.status==2) {
        this.$message.warning("已完成状态求助单不能进行编辑！")  
        return
      }
      this.updateFormVisible = true
      this.$nextTick(() => {
        this.$refs.updateForm.init(record)
      })
    },
    // 删除
    deleteEvt(record) {
        const that = this
        if(record.status==2) {
          this.$message.warning("已完成状态求助单不能进行删除！")  
          return
        }
        deleteWorder({id:record.id}).then(res=>{
            if(res.code==10000) {
                that.$message.success({
                    content: '操作成功',
                    duration: 1,
                    onClose: () => {
                        that.$refs.table.refresh(true)
                    }
                })
                } else {
                that.$message.error({
                    content: res.msg,
                    duration: 1
                })
            }
        })
    },
    // 详情
    detailFormEvt (record) {
      this.$router.push({ path: '/worder/detail', query:{worderId: record.id,changeStatus:this.changeStatus,} })
    },
  }
}
</script>

<style scoped>
.antd-page-content{
  min-height: 600px;
  overflow: hidden;
}
</style>